module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Made by Adam","short_name":"mba.","start_url":"/","background_color":"#634de2","theme_color":"#fff","display":"fullscreen","orientation":"portrait","splash_pages":null,"icon":"src/media/icon-512x512.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-160651302-1","exclude":["/preview/**","/do-not-track/me/too/"],"pageTransitionDelay":0},
    }]
